import { Button, Container, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./userProvider";

export default function Vote() {
    const [loading, setLoading] = useState(true);
    const {user} = useUser();
    const [events, setEvents] = useState([]);
    // const [votes, setVotes] = useStat/e([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/api/events')
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    navigate('/');
                }
                return null;
            }).then(data => {
                setEvents(data);
                setLoading(false);
            })
    }, [navigate]);

    if (!user) {
        navigate('/');
        return null;
    }

    if (loading) {
        return <Container maxWidth="md">Loading...</Container>
    }


    if (!user) {
        navigate('/');
        return null;
    }

    return (
        <Container maxWidth="md">
            <h1>Warwick Cyber Security Society Voting Platform</h1>
            <p>You are logged in as {user.name}. <a href="/api/auth/logout">Logout</a></p>
            <h2>Events</h2>
            {events.map(event => (
                <Stack key={event.id} direction="row" spacing={2}>
                    <h3>{event.name}</h3>
                    <Button variant="contained" color="primary" to={`/vote/${event.eventID}`} component={Link}>Vote</Button>
                </Stack>
            ))}
        </Container>
    )
}