import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid2, Paper, Radio, RadioGroup, Stack } from "@mui/material";
import { useUser } from "./userProvider";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePopups } from "./popupContext";

export default function ViewEvent() {
    const { user , setUser } = useUser();

    const { id } = useParams();

    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openVote, setOpenVote] = useState(-1);
    const [openProfile, setOpenProfile] = useState(null);
    const [confirmVote, setConfirmVote] = useState(-1);

    const [votes, setVotes] = useState({});
    const {setMessage} = usePopups();


    const navigate = useNavigate();

    useEffect(() => {
        fetch(`/api/event/${id}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw "Error fetching event";
                }
                return null;
            }).then(data => {
                setEvent(data);
                const votes = {};
                data.positions.forEach(position => {
                    // Create an array the length of the number of candidates
                    votes[position.positionID] = Array.from({ length: data.candidates.filter(candidate => candidate.positionID === position.positionID).length }, (_, i) => i);
                    // Add the ron option
                    votes[position.positionID].push(-2); 
                });
                setVotes(votes);
                setLoading(false);
            }).catch(() => {
                navigate('/');
            });
    }, [id]);

    if (!user) {
        navigate('/');
        return null;
    }

    if (loading) {
        return <Container maxWidth="md">Loading...</Container>
    }
    return (
        <Container maxWidth="md">
            <h1>Warwick Cyber Security Society Voting Platform</h1>
            <p>You are logged in as {user.name}. <a href="/api/auth/logout">Logout</a></p>
            <h2>{event.name}</h2>
            <Stack direction="column" spacing={2}>
                {event.positions.map(position => (
                    <div key={position.id}>
                        <Stack direction="row" spacing={2}>
                            <h3>{position.name} - {position.numberOfPositions}</h3>
                            {user.votes[position.positionID] ? <p>Already Voted</p> : 
                                <Button onClick={() => setOpenVote(position.positionID)} variant="contained" color="primary">Vote</Button>
                            }
                        </Stack>
                        {event.candidates.filter(candidate => candidate.positionID === position.positionID).map(candidate => (
                            <Stack key={candidate.id} direction={"row"} spacing={2} sx={{ paddingLeft: 2, paddingTop: 1 }}>
                                <h4>{candidate.candidateName}</h4>
                                <Button variant="contained" color="primary"
                                    onClick={() => setOpenProfile(candidate)}>Profile</Button>
                            </Stack>
                        ))}
                    </div>
                ))}
            </Stack>
            {openVote !== -1 && (
                <Dialog open={openVote !== -1} onClose={() => setOpenVote(-1)}>
                    <DialogTitle>Vote - {event.positions.find(position => position.positionID === openVote).name}</DialogTitle>
                    <DialogContent>
                        <FormControl>
                            {event.candidates.filter(candidate => candidate.positionID === openVote).map(candidate => (
                                <>
                                    <FormLabel key={candidate.id}>{candidate.candidateName}</FormLabel>
                                    <RadioGroup
                                        row
                                        value={votes[openVote].indexOf(candidate.candidateID)}
                                        onChange={(event) => {
                                            setVotes({
                                                ...votes,
                                                [openVote]: votes[openVote].map((vote, index) => {
                                                    if (index === parseInt(event.target.value)) {
                                                        return candidate.candidateID;
                                                    } else {
                                                        if (vote === candidate.candidateID) {
                                                            return -1;
                                                        }
                                                    }
                                                    return vote;
                                                })
                                            });
                                        }}
                                    >
                                        {votes[openVote].map((vote, index) => (
                                            <FormControlLabel key={index} value={index} control={<Radio />} label={index + 1} />
                                        ))}
                                    </RadioGroup>
                                </>
                            ))}
                            <>
                                <FormLabel key={'ron'}>Reopen Nominations</FormLabel>
                                <RadioGroup
                                    row
                                    value={votes[openVote].indexOf(-2)}
                                    onChange={(event) => {
                                        setVotes({
                                            ...votes,
                                            [openVote]: votes[openVote].map((vote, index) => {
                                                if (index === parseInt(event.target.value)) {
                                                    return -2;
                                                } else {
                                                    if (vote === -2) {
                                                        return -1;
                                                    }
                                                }
                                                return vote;
                                            })
                                        });
                                    }}
                                >
                                    {votes[openVote].map((vote, index) => (
                                        <FormControlLabel key={index} value={index} control={<Radio />} label={index + 1} />
                                    ))}
                                </RadioGroup>
                            </>
                        </FormControl>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'space-between' , padding: 2}}>
                        <Button onClick={() => setOpenVote(-1)} variant="contained" color="error">Cancel</Button>
                        {/* Clear */}
                        <Button onClick={() => {
                            setVotes({
                                ...votes,
                                [openVote]: votes[openVote].map(() => -1)
                            });
                        }} variant="contained" color="primary">Clear</Button>
                        <Button onClick={() => {
                            setConfirmVote(openVote);
                            setOpenVote(-1);
                        }} variant="contained" color="success">Vote</Button>
                    </DialogActions>
                </Dialog>
            )}
            {openProfile !== null && (
                <Dialog open={openProfile !== null} onClose={() => setOpenProfile(null)}>
                    <DialogTitle>{openProfile.candidateName}</DialogTitle>
                    <DialogContent>
                        {openProfile.manifesto !== "none" ? <p>{openProfile.manifesto}</p> : <p>No manifesto</p>}
                        {openProfile.img !== "none" ? 
                            (openProfile.img.split(";").map((img, index) => (
                                <img key={index} src={img} alt={openProfile.candidateName} />
                            ))) : <p>No image</p>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenProfile(null)}>Close</Button>
                    </DialogActions>
                </Dialog>
            )}
            {confirmVote !== -1 && (
                <Dialog open={confirmVote} onClose={() => setConfirmVote(-1)}>
                    <DialogTitle>Confirm Submission of Vote?</DialogTitle>
                    <DialogActions>
                    <Button onClick={() => {
                        setOpenVote(confirmVote);
                        setConfirmVote(-1)
                    }} variant="contained" color="error">Cancel</Button>

                    <Button onClick={() => {
                            fetch(`/api/vote`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    votes: votes[confirmVote],
                                    positionID: confirmVote
                                })
                            }).then(response => {
                                if (response.ok) {
                                    setMessage(prev => ({ ...prev, success: "Vote submitted"}));
                                    setUser(prev => ({ ...prev, votes: { ...prev.votes, [confirmVote]: true }}));
                                    setConfirmVote(-1);
                                }else {
                                    setMessage(prev => ({ ...prev, error: "Error submitting vote"}));
                                    // Save the votes to a csv file locally
                                    const csv = votes[confirmVote].map(vote => {
                                        if (vote === -2) {
                                            return "RON";
                                        }
                                        return event.candidates.find(candidate => candidate.candidateID === vote).candidateName;
                                    }).join("\n");
                                    const element = document.createElement("a");
                                    const file = new Blob([csv], { type: 'text/csv' });
                                    element.href = URL.createObjectURL(file);
                                    element.download = `${event.positions.find(position => position.positionID === confirmVote).name}.csv`;
                                    document.body.appendChild(element);
                                    element.click();
                                }
                            })
                        }} variant="contained" color="success">Submit</Button>
                        </DialogActions>
                </Dialog>
            )}
        </Container>
    )
}