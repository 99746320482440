import { Alert, Snackbar } from "@mui/material";
import { usePopups } from "../components/popupContext";


function Popups() {
    const { message, setMessage } = usePopups();

    function handleClose(type) {
        switch (type) {
            case 'error':
                setMessage((prev) => ({ ...prev, error: null }));
                break;
            case 'success':
                setMessage((prev) => ({ ...prev, success: null }));
                break;
            case 'info':
                setMessage((prev) => ({ ...prev, info: null }));
                break;
            case 'warning':
                setMessage((prev) => ({ ...prev, warning: null }));
                break;
            default:
                break;
        }
    }


    return (
        <>
        <Snackbar open={message.error != null} 
        autoHideDuration={1000} 
        onClose={() => handleClose('error')}
        >
            <Alert severity="error" variant='filled' onClick={() => { handleClose('error') }}
            >{message.error}</Alert>
        </Snackbar>

        <Snackbar open={message.success != null}
        autoHideDuration={1000}
        onClose={() => handleClose('success')}
        >
            <Alert severity="success" variant='filled' onClick={() => { handleClose('success') }}
            >{message.success}</Alert>
        </Snackbar>

        <Snackbar open={message.info != null}
        autoHideDuration={1000}
        onClose={() => handleClose('info')}
        >
            <Alert severity="info" variant='filled' onClick={() => { handleClose('info') }}
            >{message.info}</Alert>
        </Snackbar>

        <Snackbar open={message.warning != null}
        autoHideDuration={1000}
        onClose={() => handleClose('warning')}
        >
            <Alert severity="warning" variant='filled' onClick={() => { handleClose('warning') }}
            >{message.warning}</Alert>
        </Snackbar>
        </>
    );
}



export default Popups;