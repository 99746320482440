import React, { createContext, useContext, useState, ReactNode } from 'react';

const PopupContext = createContext(null);

export const PopupProvider = ({ children }) => {
    const [message, setMessage] = useState({
        error: null,
        success: null,
        info: null,
        warning: null,
    });


    return (
        <PopupContext.Provider value={{ message, setMessage }}>
            {children}
        </PopupContext.Provider>
    );
};

export const usePopups = () => {
    const context = useContext(PopupContext);
    if (!context) {
        throw new Error('useMessage must be used within a MessageProvider');
    }
    return context;
};