import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useUser } from "./userProvider";

export default function Home() {
    const {user, setUser} = useUser();

    useEffect(() => {
        fetch('/api/user')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                return null;
            }).then(data => {
                setUser(data);
            })
        }, []);

    if (user) {
        return (
            <Container maxWidth="md">
                <h1>Welcome to the Warwick Cyber Security Society Voting Platform, {user.name}</h1>
                <p>Click here to vote <a href="/api/auth/logout">Logout</a></p>
                <Button variant="contained" color="primary" to="/vote" component={Link}>Vote</Button>
            </Container>
        )
    }

    return (
        <Container maxWidth="md">
            <h1>Welcome to the Warwick Cyber Security Society Voting Platform</h1>
            <p>Please log in to vote</p>
            <Button variant="contained" color="primary" href="/api/auth/warwick-sso/">Login</Button>
        </Container>
    )
}