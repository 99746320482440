import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './components/home';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Vote from './components/vote';
import ViewEvent from './components/viewEvent';
import { UserProvider } from './components/userProvider';
import { PopupProvider } from './components/popupContext';
import Popups from './components/popups';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <UserProvider>
          <PopupProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/vote" element={<Vote />} />
              <Route path="/vote/:id" element={<ViewEvent />} />
              {/* <Route path="dashboard" element={<Dashboard />}>
            <Route index element={<RecentActivity />} />
            <Route path="project/:id" element={<Project />} />
          </Route> */}
            </Routes>
            <Popups />
          </PopupProvider>
        </UserProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}
